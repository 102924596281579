<template>
  <div class="project">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'ProjectsList' }">Projects</router-link>
      </CBreadcrumbItem>

      <CBreadcrumbItem>
        <CSelectSearch
          v-model="selectedProject"
          class="project__select"
          :options="projectsList"
          :search="true"
          :loading="loadingProjects"
        />
      </CBreadcrumbItem>
    </teleport>

    <teleport v-if="mountedComponent" to="#projects-header">
      <TopNavigation :links="topNavLinks" />
    </teleport>

    <router-view />
  </div>
</template>

<script>
import breadcrumbsMixin from '../breadcrumbsMixin'
import navLinkMixin from '../navLinkMixin'
import { computed } from 'vue'
export default {
  name: 'Index',
  mixins: [breadcrumbsMixin, navLinkMixin],
  provide() {
    return {
      mountedComponent: computed(() => this.mountedComponent),
    }
  },
}
</script>

<style lang="scss">
.project {
  &__select {
    width: 182px;
  }
}
</style>
