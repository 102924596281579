<template>
  <div class="topNavigation__header">
    <div :key="key" class="mobile-hide" style="flex-wrap: wrap">
      <router-link
        v-for="(link, index) in linksList"
        :key="index"
        tag="button"
        class="btn btn-ghost-dark mx-3"
        :class="[
          isActive(link) ? 'activeBtn' : '',
          link.disabled ? 'disabled' : '',
        ]"
        :to="''"
        @click.stop="toLink(link)"
      >
        {{ link.title }}
      </router-link>
    </div>
    <CDropdown v-if="linksList.length" color="primary" class="pc-hide m-2 ml-0">
      <CDropdownToggle
        style="padding: 7px 28px; border-radius: 8px"
        color="primary"
        >{{ currentRouteTitle }}</CDropdownToggle
      >
      <CDropdownMenu>
        <CDropdownItem
          v-for="(link, index) in linksList.filter(
            (l) => l.route.name !== currentRouteName,
          )"
          :key="index"
          @click="toLink(link)"
        >
          {{ link.title }}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
    <div
      v-if="actionLinks.length && !$slots.actions"
      class="my-2"
      style="margin-left: auto"
    >
      <Action :links="actionLinksList" />
    </div>
    <div v-if="$slots.actions" style="margin-left: auto">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
/**
 * Array of objects
 * links: [
 *  {
 *    title: String                 title for User
 *    hidden: Boolean               if set true, item will not show
 *    guard: Function               return Boolean
 *    permissions: ['permission']   control the page permissions
 *    route: {
 *      name: String                route name
 *      params: Object              route params
 *    }
 *  }
 * ]
 */
import { mapGetters } from 'vuex'
import { hasRoutePermission } from '@/utils/helper'
import Action from './Action'

export default {
  name: 'TopNavigation',
  components: { Action },
  props: {
    links: { type: Array, default: () => [] },
    actionLinks: { type: Array, default: () => [] },
  },
  data() {
    return {
      key: 0,
    }
  },
  computed: {
    ...mapGetters(['user']),
    currentRouteTitle() {
      let name = ''
      this.links.forEach((item) => {
        if (this.isActive(item)) name = item.title
      })

      return name
    },
    currentRouteName() {
      return this.$router.currentRoute.value.name
    },
    linksList() {
      return this.links.filter((item) => {
        return item.hidden !== true && this.checkPermission(item)
      })
    },
    actionLinksList() {
      return this.actionLinks.filter((item) => {
        return this.checkPermission(item)
      })
    },
  },
  methods: {
    checkPermission(route) {
      return hasRoutePermission(route)
        ? this.user.canAll(route.permissions)
        : true
    },
    toLink(data) {
      let route = data.route
      if (!this.$router.hasRoute(route.name)) return false

      let check = true
      if (typeof data.guard === 'function') check = data.guard()
      if (!check) this.key++
      if (check) this.$router.push(route)
    },
    isActive(item) {
      return (
        this.currentRouteName === item.route.name ||
        (Array.isArray(item.children) &&
          item.children.includes(this.currentRouteName))
      )
    },
  },
}
</script>

<style scoped lang="scss">
.topNavigation {
  &__header {
    width: 100%;
    display: flex;
  }
}

.btn-ghost-dark {
  color: #1c262f !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-radius: unset !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0);
  --cui-btn-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0);
}

.btn-ghost-dark:focus,
.btn-ghost-dark:hover {
  background-color: rgba(255, 255, 255, 0) !important;
  border-top-color: rgba(255, 255, 255, 0) !important;
  border-left-color: rgba(255, 255, 255, 0) !important;
  border-right-color: rgba(255, 255, 255, 0) !important;
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0);
}

.btn-ghost-dark:hover {
  border-bottom: 2px solid #c1cbd3 !important;
}

.activeBtn {
  border-bottom: 2px solid #0081c2 !important;
}

.activeBtn:hover {
  border-bottom: 2px solid #0081c2 !important;
}
</style>
