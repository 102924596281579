<template>
  <CDropdown>
    <CDropdownToggle
      class="d-flex justify-content-between align-items-center"
      color="primary"
      style="padding: 7px 28px; border-radius: 8px; height: 40px; width: 200px"
      >Action</CDropdownToggle
    >
    <CDropdownMenu>
      <template v-for="(link, index) in linksList" :key="index">
        <CDropdownItem
          v-if="!link.onlyRoute || link.onlyRoute === $route.name"
          :disabled="link.disabled === true"
          @click="handleChange(link)"
        >
          {{ link.title }}
        </CDropdownItem>
      </template>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
/**
 * Array of objects
 * links: [
 *  {
 *    title: String                 title for User
 *    hidden: Boolean               if set true, item will not show
 *    disabled: Boolean               if set true, item will be disabled
 *    permissions: ['permission']   control the page permissions
 *    emit: String                 priority above the router
 *    route: {
 *      name: String                route name
 *      params: Object              route params
 *    }
 *  }
 * ]
 */
import { hasRoutePermission } from '@/utils/helper'

export default {
  props: {
    links: { type: Array, default: [] },
  },
  data() {
    return {
      scopeModal: false,
    }
  },
  computed: {
    linksList() {
      return this.links.filter((item) => {
        return item.hidden !== true && this.checkPermission(item)
      })
    },
  },
  methods: {
    checkPermission(route) {
      return hasRoutePermission(route)
        ? this.authUser.canAll(route.permissions)
        : true
    },
    handleChange(link) {
      link.emit ? this.$emit(link.emit, link) : this.toLink(link.route)
    },
    toLink(route) {
      if (!this.$router.hasRoute(route.name)) return false
      this.$router.push(route)
    },
  },
}
</script>
