import CSelectSearch from '@/components/CSelectSearch'

export default {
  components: { CSelectSearch },
  data() {
    return {
      selectedProject: +this.$route.params.id || null,
      projectsList: [],
      loadingProjects: false,
      ignoreSelectingProject: false,

      mountedComponent: false,
    }
  },
  watch: {
    async selectedProject(projectId) {
      if (this.ignoreSelectingProject) {
        this.ignoreSelectingProject = false
        return
      }

      if (!projectId) return

      await this.$router
        .push({
          name: 'Project',
          params: {
            id: projectId,
          },
        })
        .catch(() => {
          this.selectedProject = +this.$route.params.id || null
          this.ignoreSelectingProject = true
        })
    },
  },
  async mounted() {
    this.mountedComponent = true
    await this.fetchProjectsList()
  },
  methods: {
    fetchProjectsList() {
      this.loadingProjects = true
      this.$http.projects
        .fetchProjects({
          params: {
            per_page: -1,
            page: 1,
            sort_field: '',
            sort_direction: '',
          },
        })
        .then(({ data }) => {
          this.projectsList = data.data
            ?.map((el) => ({
              value: el.id,
              label: el.name,
            }))
            ?.sort((a, b) => b.value - a.value)
        })
        .finally(() => {
          this.loadingProjects = false
        })
    },
  },
}
